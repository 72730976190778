import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import Modal from "react-modal";
import { Container, Table } from "react-bootstrap";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();
const s3 = new AWS.S3();
const databaseName = process.env.REACT_APP_TABLE_NAME;
const bucketName = process.env.REACT_APP_BUCKET_NAME;

const ViewBooks = () => {
    const navigate = useNavigate();
    const isAdminAuthenticated = localStorage.getItem("isAdminAuthenticated");
    const [books, setBooks] = useState([]);    
    const [selectedBook, setSelectedBook] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [bookText, setBookText] = useState("");
    const [playbackRate, setPlaybackRate] = useState(1);
    const [bookContent, setBookContent] = useState("");
    const [controlsVisible, setControlsVisible] = useState(false);
    const [speechPaused, setSpeechPaused] = useState(false);


    let speechUtterance = null;

    const fetchBooks = async () => {
      const params = {
        TableName: databaseName,
      };
  
      try {
        const result = await dynamoDB.scan(params).promise();
        const updatedBooks = result.Items.map((book) => ({
          ...book,
          s3Key: `${book.id}-${book.title.replace(/\s+/g, "-")}.txt`,
        }));
        
        const sortedBooks = updatedBooks.sort(sortBooks);

        setBooks(sortedBooks);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };

    useEffect(() => {
        if (isAdminAuthenticated !== "true") {
          navigate("/admin");
          return;
        }
      
        fetchBooks();
      }, [isAdminAuthenticated, navigate]);

    const readBook = async (book) => {
      try {
        const params = {
          Bucket: bucketName,
          Key: book.s3Key,
        };
        const response = await s3.getObject(params).promise();
        const text = new TextDecoder().decode(response.Body);
        setBookContent(text);
        setSelectedBook(book);
        setBookText(text)
        setModalIsOpen(true);
      } catch (error) {
        console.error("Error fetching book content:", error);
      }
    };
  
    const closeModal = () => {
      setModalIsOpen(false);
      setControlsVisible(false);
      if (!speechPaused) {
        speechSynthesis.pause();
      }
    };

    const handleApprove = async (bookId) => {
    const params = {
    TableName: databaseName,
    Key: { id: bookId },
    UpdateExpression: "set #s = :status",
    ExpressionAttributeNames: { "#s": "status" },
    ExpressionAttributeValues: { ":status": "approved" },
  };
  try {
    await dynamoDB.update(params).promise();
    // Reload the books to reflect the updated status
    fetchBooks();
    closeModal();
  } catch (error) {
    console.error("Error updating book status:", error);
  }
};

const handleReject = async (bookId) => {
  const params = {
    TableName: databaseName,
    Key: { id: bookId },
    UpdateExpression: "set #s = :status",
    ExpressionAttributeNames: { "#s": "status" },
    ExpressionAttributeValues: { ":status": "rejected" },
  };
  try {
    await dynamoDB.update(params).promise();
    // Reload the books to reflect the updated status
    fetchBooks();
    closeModal();
  } catch (error) {
    console.error("Error updating book status:", error);
  }
};


const speakText = () => {
  if (speechPaused) {
    speechSynthesis.resume();
    setSpeechPaused(false);
  } else {
    if (bookText) {
      speechUtterance = new SpeechSynthesisUtterance(bookText);
        // Get available voices
        const voices = speechSynthesis.getVoices();

        // Get the preferred voice
        const preferredVoice = getPreferredVoice(voices);
  
        if (preferredVoice) {
          speechUtterance.voice = preferredVoice;
        }
        
      speechSynthesis.speak(speechUtterance);
      setControlsVisible(true);
    }
  }
};



  const pauseText = () => {
    if (speechPaused) {
      speechSynthesis.resume();
    } else {
      speechSynthesis.pause();
    }
    setSpeechPaused(!speechPaused);
  };

  const cyclePlaybackRate = () => {
    let newRate;
    if (playbackRate === 1) {
      newRate = 1.5;
    } else if (playbackRate === 1.5) {
      newRate = 2;
    } else {
      newRate = 1;
    }
    setPlaybackRate(newRate);
    changePlaybackRate(newRate);
  };

  const getPreferredVoice = (voices) => {
    // You can set your preference order for the voices
    const preferredVoices = [
      'Google US English',
      'Google UK English Female',
      'Microsoft Zira Desktop - English (United States)',
    ];
  
    for (const preferredVoice of preferredVoices) {
      const voice = voices.find((voice) => voice.name === preferredVoice);
      if (voice) {
        return voice;
      }
    }
  
    // If no preferred voice is found, return the first available English voice
    return voices.find((voice) => voice.lang.startsWith('en'));
  };
  
  const changePlaybackRate = (rate) => {
    setPlaybackRate(rate);
    if (speechUtterance) {
      speechUtterance.rate = rate;
      speechSynthesis.cancel(); // Stop the current speech
      speechSynthesis.speak(speechUtterance); // Restart the speech with the new rate
    }
  };
  
  const rewindText = () => {
    if (speechUtterance) {
      speechSynthesis.pause();
      const rewindTime = 15; // In seconds
      const rewindChars = Math.round(bookText.length * (rewindTime / speechUtterance.elapsedTime));
      const rewindIndex = Math.max(speechUtterance.charIndex - rewindChars, 0);
      const newText = bookText.slice(rewindIndex);
      speechUtterance = new SpeechSynthesisUtterance(newText);
      speechUtterance.rate = playbackRate;
      speechUtterance.voice = speechUtterance.voice; // Preserve the voice
      speechSynthesis.speak(speechUtterance);
    }
  };
  


  const sortBooks = (a, b) => {
    const statusOrder = ["needs review", "approved", "rejected"];
  
    // Sort by status
    if (statusOrder.indexOf(a.status) < statusOrder.indexOf(b.status)) {
      return -1;
    } else if (statusOrder.indexOf(a.status) > statusOrder.indexOf(b.status)) {
      return 1;
    }
  
    // Sort alphabetically within the same status
    return a.title.localeCompare(b.title);
  };

  
    return (
      <div>
      <nav>
        <a href="/">Home</a>
        <a href="/submit-book">Submit Book</a>
        <a href="/admin/view-books">View Books</a>
      </nav> 
      <div className="contentInner">
        <h1 className="my-4">View Books</h1>
        <table striped bordered hover>
          <thead>
            <tr>
              <th className="firstColumn">Title</th>
              <th>Genre</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {books.map((book) => (
              <tr key={book.id}>
                <td>{book.title}</td>
                <td>{book.genre}</td>
                <td>{book.status ? book.status : "needs review"}</td>
                <td>
                    <button variant="primary" onClick={() => readBook(book)}>Read Book</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {selectedBook &&
          <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
            <button className="floatRight" onClick={closeModal}>X</button>
            <h2>{selectedBook.title}</h2>
            <h5>Author: {selectedBook.author}</h5>
            <h5>Genre: {selectedBook.genre}</h5>
            <div className="adminTooltip">
              <p>Original content: {selectedBook.aiOriginalValue}% ({100 - selectedBook.aiOriginalValue}% ai generated)</p>
              <p>Plagiarism rating: {selectedBook.plagiarismScore}% (lower is better, anything over 50-75% we should probably avoid)</p>
            </div>
            <div className="readittome">
              <button onClick={speakText}>Read Aloud</button>
            </div>
            <pre>{bookContent}</pre>
            <div>
              <label htmlFor="reviewer">Reviewer:</label>
              <input className="modalButton" type="text" id="reviewer" name="reviewer" />
            </div>
            <div>
              <button className="modalButton" variant="primary" onClick={() => handleApprove(selectedBook.id)}>Approve</button>
              <button className="modalButton" variant="primary" onClick={() => handleReject(selectedBook.id)}>Reject</button>
            </div>
            <button className="modalButton" variant="primary" onClick={closeModal}>Close</button>

            {controlsVisible && (
              <>
              <div className="playbackControls">
                <button onClick={pauseText}>
                  <i className={speechPaused ? "icon-play" : "icon-pause" } />
                </button>
                  <button onClick={cyclePlaybackRate}>
                    {playbackRate}x
                  </button>
                  <button onClick={rewindText}>
                    <i className="icon-back-in-time" />
                  </button>
              </div>
              </>
            )}
          </Modal>
        }

      </div>
      </div>
    );
  };
  

export default ViewBooks;
