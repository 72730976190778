import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import SubmitBook from './SubmitBook';
import AdminLogin from './admin/AdminLogin';
import ViewBooks from './admin/ViewBooks';
import TermsOfService from './ToS';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <div className="AppHomepage">
      <header>
        <div className="greenFakeButton">
          867-5309
        </div>
        <a href="/" className="goldWrapper">
          <h1>Whispering Woods</h1>
          <h2>Public Library</h2>
        </a>
        <div className="greenFakeButton">
          est. 2023
        </div>

        <div className="brownHR"></div>
        {/* <nav>
          <Link to="/">Home</Link>
          <Link to="/submit-book">Submit Book</Link>
          <Link to="/admin/view-books">View Books</Link>
        </nav> */}

      </header>
      
      <div className="pageContent">
      <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/submit-book" element={<SubmitBook />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/admin/view-books" element={<ViewBooks />} />
        </Routes>

      </div>      
      <footer className="main-footer">
        <p><span>SUPERNEAT</span></p>
        <p>&copy; 2023 SuperNeat, Inc. All Rights Reserved.
          <br></br><a href="/tos">Terms of Service</a> | Privacy Policy</p>
      </footer>
    </div>
  );
}

// Create a separate HomePage component for the home page content
function HomePage() {
  return (
    <div>

      <div className="home-container">
        <div className="heroImage">
        </div>
        <div className="threeBlocks">
        <h1>Welcome to <br></br>The Whispering Woods Public Library</h1>
        <p>How can we help you today?</p>
          <div className="threeBlocksInner">
            <a href="/submit-book" className="block1"><span>Submit <br></br>Your <br></br>Short Story</span><div className="overlay"></div></a>
            <a href="/admin/view-books" className="block2" ><span>View Books</span><div className="overlay"></div></a>
            <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" className="block3"><span>Add us  <br></br>to your <br></br>Wishlist</span><div className="overlay"></div></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
