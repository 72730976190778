import React from 'react';


const TermsOfService = () => {
     
    return (
      <div className="generalContent">
        <h1>Terms of Service</h1>
        <p>By submitting your short story to SuperNeat, Inc., you agree to the following terms and conditions:
        </p><p>
Originality: You represent and warrant that the submitted short story is your own original work, has not been previously published, and does not infringe upon any copyright, trademark, or other intellectual property rights of any third party.
</p>
<p>
No AI-generated content: You confirm that the submitted short story has not been generated using artificial intelligence or any other automated means.
</p>
<p>
Grant of Rights: By submitting your short story, you grant SuperNeat, Inc. an exclusive, perpetual, irrevocable, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the short story in connection with the video game and any other related activities, in any media formats and through any media channels.
</p>
<p>
Waiver of Monetization Rights: You acknowledge and agree that you will not receive any compensation or royalties for the use of your submitted short story in the video game or any related activities. You waive any rights to future monetization or claims to revenue generated from the use of your short story in the video game or related activities by SuperNeat, Inc. or its sublicensees.
</p>
<p>
Indemnification: You agree to indemnify, defend, and hold harmless SuperNeat, Inc., its affiliates, and their respective officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your breach of the representations, warranties, or agreements contained in these terms and conditions.
</p>

      </div>
    );
  };
  
  export default TermsOfService;