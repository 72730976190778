import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";
import { containsBadWords } from './utils';
import { getRandomBlock, checkOriginality } from "./CheckOriginality";

// Configure AWS
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  databaseName: process.env.REACT_APP_TABLE_NAME
});

const s3 = new AWS.S3();
const dynamoDB = new AWS.DynamoDB.DocumentClient();
const bucketName = process.env.REACT_APP_BUCKET_NAME;
const databaseName = process.env.REACT_APP_TABLE_NAME;

const SubmitBook = () => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [authorContact, setAuthorContact] = useState("ai@lastlibrarian.com");
  const [authorContactType, setAuthorContactType] = useState("");
  const [genre, setGenre] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [uploadMode, setUploadMode] = useState(true);
  const [textContent, setTextContent] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setSubmitting(true);
    setStatusMessage("Checking for duplicate submissions...");

    if (!validateEmail(authorContact)) {
      setError("Please enter a valid email address.");
      return;
    }
    
    let inputText;
    if (uploadMode) {
      inputText = file.text;
    } else {
      inputText = textContent;
    }

      // Check if the text contains bad words
    if (containsBadWords(inputText)) {
      setError("Unable to submit: The uploaded text contains inappropriate language.");
      return;
    }

    // Check if a book with the same title already exists
    const queryParams = {
      TableName: databaseName,
      IndexName: "title-index",
      KeyConditionExpression: "title = :title",
      ExpressionAttributeValues: {
        ":title": title,
      },
    };

    try {
      const queryResult = await dynamoDB.query(queryParams).promise();
      if (queryResult.Items.length > 0) {
        setError("A book with that title has already been submitted.");
        setSubmitting(false);
        setStatusMessage("");
        return;
      }

      // If not, save the book data to DynamoDB and upload the file to S3
      const id = uuidv4();
      const sanitizedText = inputText.replace(/[^a-zA-Z0-9\s.,!?']/g, "");

      // Get a random 150-word block
      const randomBlock = getRandomBlock(sanitizedText);

      // Check originality and plagiarism using Originality.ai API
      setStatusMessage(
        <>
          <span className="status">Checking for plagiarism and AI content. This can take up to 2 minutes. Please wait...</span>
        </>);
      var newBlock = title + randomBlock;

      const originalityData = await checkOriginality(newBlock);

      // add back in if we want to reject AI-generated text
      // if (originalityData.aiOriginalValue < 90) { 
      //   setError("Text is likely generated by AI. Was this an error? Contact us. "); return; 
      // }

      if (originalityData.plagiarismScore > 75) { 
        setError(
          <>
            <p><strong>Unable to submit: The uploaded text appears to be {originalityData.plagiarismScore}% plagiarized content.</strong></p>
            <p>Are you the original author? Contact us with the details of your short story and proof of identiy and authorship so we can be sure include your work in consideration.</p>
            <p><a href="mailto:the@lastlibrarian.com">the@lastlibrarian.com</a></p>
          </>
        ); 
        
        return; 
      }

      const s3Params = {
        Bucket: bucketName,
        Key: `${id}-${title.replace(/\s+/g, "-")}.txt`,
        Body: sanitizedText,
        ContentType: "text/plain; charset=utf-8",
    };

    const fileUrl = `https://${bucketName}.s3.amazonaws.com/${s3Params.Key}`;

    const dynamoDBParams = {
      TableName: databaseName,
      Item: {
        id,
        title,
        author,
        authorContact,
        authorContactType,
        genre,
        fileUrl,
        status: "needs review",
        aiOriginalValue: originalityData.aiOriginalValue,
        plagiarismScore: originalityData.plagiarismScore,
      },
    };

    // Upload file to S3
    await s3.upload(s3Params).promise();

    // Save book data to DynamoDB
    await dynamoDB.put(dynamoDBParams).promise();

    setSuccess(
    <>
      <p><strong>Thanks for your submission!</strong></p>

      <p>Not all submissions will be accepted. You will receive an email confirmation in the future if your submission is accepted.</p>
      <p>admin</p>
      <p>
        Original, non-ai content detected: {originalityData.aiOriginalValue}% <br />
        Plagiarism detected: {originalityData.plagiarismScore}%
      </p>
    </>  
    );
  } catch (error) {
    console.error('Error submitting the book:', error); // Add this line to log the actual error
    setError("There was an error submitting the book. Please try again.");
  } finally {
    setSubmitting(false);
    setStatusMessage("");
  }
};

const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      setFile({ ...file, text: e.target.result });
    };
    reader.readAsText(file, 'UTF-8');
  }
};

const handleTextContentChange = (e) => {
  const inputText = e.target.value;
  const utf8Text = new TextEncoder().encode(inputText);
  const decodedText = new TextDecoder().decode(utf8Text);
  setTextContent(decodedText);
};

const validateEmail = (email) => {
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/;

  return emailRegex.test(email);
};


return (
  <div>
      <nav>
        <a href="/">Home</a>
        <a href="/submit-book">Submit Book</a>
        <a href="/admin/view-books">View Books</a>
      </nav> 
    
    <div className="contentInner">
      <h1 className="my-4">Submit a Short Story</h1>
      {error && <p className="error">{error}</p>}
      {success ? (
        <div className="generalContent"><p className="success">{success}</p></div> ) : (
        <form onSubmit={handleSubmit}>
            <div className="generalContent">
              <p>Thanks for your interest in submitting your short story for consideration to be featured in our upcoming game, The Last Librarian.</p>
              <p>Not all submissions will be accepted and each submission will be scanned for originality, plagarism, and ai content generation.</p>
              <p>Here is what we're looking for:</p>
              <ul>
                <li>Original work that you wrote yourself.</li>
                <li>Human written content. No AI generated copy.</li>
                <li>1000-2500 word short stories. Fewer words ok. Hard cap at 5000 words.</li>
                <li>Family friendly language and subject matter.</li>
              </ul>
              <p className="disclaimerText">By submitting your story you agree to allow SuperNeat, Inc. to use your work in the future release of our game without compensation and agree to these <a href="/tos">terms</a>.</p>
            </div>
            <label>
            Title:
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
            </label>
            <br />
            <label>
            Author:
            <input type="text" value={author} onChange={(e) => setAuthor(e.target.value)} required />
            </label>
            <br />
            <label>
            Author Contact:
            <input type="text" paceholder="ai@lastlibrarian.com" value={authorContact} onChange={(e) => setAuthorContact(e.target.value)} required />
            </label>
            <br />
            <label>
            Author Contact Type:
            <select value={authorContactType} onChange={(e) => setAuthorContactType(e.target.value)} required>
                <option value="">Select Type</option>
                <option value="email">Email</option>
                <option value="twitter">Twitter</option>
                <option value="instagram">Instagram</option>
            </select>
            </label>
            <br />
            <label>
            Genre:
            <select value={genre} onChange={(e) => setGenre(e.target.value)} required>
                <option value="">Select genre</option>
                <option value="fantasy">Fantasy</option>
                <option value="mystery-thriller">Mystery / Thriller</option>
                <option value="children-stories">Children's Stories</option>
                <option value="fairy-tales">Fairy Tales</option>
                <option value="science-fiction">Science Fiction</option>
                <option value="action-adventure">Action Adventure</option>
                <option value="humor">Humor</option>
                <option value="poetry">Poetry</option>
                <option value="classics">Classics</option>
                <option value="fiction">Fiction</option>
              </select>
            </label>
            <br />
            {/* <label>
                Upload Book (.txt only):
                <input type="file" accept=".txt" onChange={handleFileChange} required />
            </label> */}

            <label>
              Book Copy Source:
              <label>
                <input
                  type="radio"
                  value="file"
                  checked={uploadMode}
                  onChange={() => setUploadMode(true)}
                />
                Upload File
              </label>
              <label>
                <input
                  type="radio"
                  value="text"
                  checked={!uploadMode}
                  onChange={() => setUploadMode(false)}
                />
                Paste Text
              </label>
            </label>
            <br />
            {uploadMode ? (
              <label>
                Upload Book (.txt only):
                <input
                  type="file"
                  accept=".txt"
                  onChange={handleFileChange}
                  required
                />
              </label>
            ) : (
              <label style={{"width" : "100%"}}>
                Paste Book Content:
                <textarea
                  rows="40"
                  value={textContent}
                  onChange={handleTextContentChange}
                  required
                  style={{ "width": "100%"}}
                />
              </label>
            )}

            <br />
            <button type="submit" disabled={submitting}>{submitting ? "Submitting..." : "Submit"}</button>

          </form> 
        )}
          {submitting && <p className="status">{statusMessage}</p>}
        </div>
      </div>
     );
   };

   export default SubmitBook;
