import axios from "axios";

export const getRandomBlock = (text, blockSize = 149) => {
  const words = text.split(/\s+/);
  if (words.length <= blockSize) {
    return text;
  }

  const start = Math.floor(Math.random() * (words.length - blockSize));
  const end = start + blockSize;
  console.log("checking for plagiarism and ai");
  return words.slice(start, end).join(" ");
};

export const checkOriginality = async (textBlock) => {
  const apiUrl = "https://api.originality.ai/api/v1/scan/ai-plag";
  const apiKey = "09rxzq2637uyodbg1jmsitfk5plnhwae"; // Replace with your API key

  try {
    const response = await axios.post(
      apiUrl,
      { content: textBlock },
      { headers: { "X-OAI-API-KEY": apiKey } }
    );

    var plagPercent = Number(response.data.plagiarism.total_text_score.replace("%",""));
    var aiContent = Math.round((response.data.ai.score.original + Number.EPSILON) * 100) / 100;

    const originalityData = {
      aiOriginalValue: aiContent,
      plagiarismScore: plagPercent,
    };

    return originalityData;
  } catch (error) {
    console.error("Error checking originality:", error);
    return { aiOriginalValue: null, plagiarismScore: null };
  }
};
